import React, { useEffect, useState, useRef, useId, useContext } from "react";
import { GrCopy } from "react-icons/gr";
import { baseEndpoint } from "../API/endpoints";
import axios from "axios";
import { jsPDF } from "jspdf";
import { LuDownload } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiBookmark } from "react-icons/ci";
import './Mcq.css'

import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from "react-router-dom";
import Formpage from "./Mainform/Rightsideform/Form";
import { IoBookmark } from "react-icons/io5";



const MCQ = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedClass, selectedSubject, selectedTitle, selectedChapters, numberOfQuestions: initQuestions, difficulty: initDifficulty, autoSubmit } = location.state || {};

    const [showAnswers, setShowAnswers] = useState(false);
    const handleAnswersToggle = (checked) => {
        setShowAnswers(checked);
    };

    const anchorRef = useRef(null);
    const [questions, setQuestions] = useState([]);
    console.log(questions,"it is all questions")
    const [variantQuestions, setVariantQuestions] = useState([]);
    const [anchor, setAnchor] = React.useState(null);
    const hasSubmitted = useRef(false);

    const [questionsHeading, setquestionsHeading] = useState(null);
    const [mcqResponseData, setmcqResponseData] = useState([]);
    console.log(mcqResponseData,'it is setting all data')

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [classValue, setClassValue] = useState(selectedClass || '');
    const [subjectValue, setSubjectValue] = useState(selectedSubject || '');
    const [titleValue, setTitleValue] = useState(selectedTitle || '');
    const [chapterValues, setChapterValues] = useState(selectedChapters || []);
    const [numberOfQuestions, setNumberOfQuestions] = useState(initQuestions || '1');
    const [difficulty, setDifficulty] = useState(initDifficulty || 'medium');
    const [topicValues, setTopicValues] = useState([]);
    const [isstarred, setStarred]=useState(false)
    const [isregenareteshow,setIsregenareteshow]=useState(false)
    const [ishistory, setIshistory]=useState(false)

    const [formData, setFormData] = useState(null);
    const [sideFormData, setSideFormData] = useState();

    const [hoveredQuestion, setHoveredQuestion] = useState(null);
    const [showDropdown, setShowDropdown] = useState(null);
    const handleReportandDelete = async (index,qIndex,status)=>{
        setQuestions(prevQuestions => {
            const updatedQuestions = [...prevQuestions];  // Copy the current state
            updatedQuestions[index][qIndex].state = status;  // Update the status to 'deleted' for the selected question
            return updatedQuestions;  // Set the updated state
          });

          try {
            const userDetail = JSON.parse(localStorage.getItem('userdetail')) || JSON.parse(localStorage.getItem('userdata'));
            const user_id = userDetail?.id;
            const rid = mcqResponseData[index][0].id
            const endpoint = `${baseEndpoint}/editable/users/${user_id}/responses/${rid}/flagquestion`;
        
            const response = await axios({
              method: 'post',
              url: endpoint,
              data:{
                index_number : qIndex,
                state : status,
                reason:'',
              }
            });
        
            const data = response.data; 
            console.log(data, "it is the new response data");
        
          } catch (error) {
            console.error('Error:', error);
          }

    }
    const handleQuestionAction = (action, groupIndex, questionIndex) => {
        switch(action) {
            case 'delete':
                handleReportandDelete(groupIndex,questionIndex,"deleted")
                console.log('Deleting question:', groupIndex, questionIndex);
                break;
            case 'report':
                handleReportandDelete(groupIndex,questionIndex,"reported")
                console.log('Reporting question:', groupIndex, questionIndex);
                break;
            default:
                break;
        }
        setShowDropdown(null);
    };


    useEffect(() => {
        if (location.state) {
            const mainFormData = {
                standard: location.state.selectedClass,
                subject: location.state.selectedSubject,
                topic: '',
                file_name: location.state.selectedChapters,
                title: location.state.selectedTitle
            };
            setFormData(mainFormData);
        }
    }, [location.state]);

    

    useEffect(() => {
        if (autoSubmit && !hasSubmitted.current && 
            selectedClass && selectedSubject && selectedTitle && 
            selectedChapters?.length > 0 && !loading) {
            hasSubmitted.current = true;
            handleSubmit({
                standard: selectedClass,
                subject: selectedSubject,
                topic: '',
                file_name: selectedChapters,
                number: initQuestions,
                complexity: initDifficulty,
                title: selectedTitle,
            });
            setQuestions([]);
            setmcqResponseData([]);  
        }
    }, [selectedClass, selectedSubject, selectedTitle, selectedChapters, loading]);

    const handleSubmit = async (formData) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        setLoading(true);
        setError(null);
        setSideFormData(formData);
        setStarred(false)
        setIshistory(false)
        setIsregenareteshow(true)
        setQuestions([]);
        setmcqResponseData([]); 
        const accessToken = localStorage.getItem('access_token');
        const googleToken = localStorage.getItem('token');
    
        if (!accessToken && !googleToken) {
            // alert('Login to generate content');
            // console.error('No access token or Google token found, redirecting to login...');
            // window.location.href = '/signin';
            navigate('/signin', { state: { from: location.pathname ,previousState: location.state,} });
            return;
        }
    
        const userDetail = JSON.parse(localStorage.getItem('userdetail')) || JSON.parse(localStorage.getItem('userdata'));
        const user_id = userDetail?.id;
    
        const endpoint = `${baseEndpoint}/mcq/${user_id}/mcq_stream`;
        const body = {
            standard: formData.standard,
            subject: formData.subject,
            topic: formData.topic || '',
            file_name: formData.file_name,
            number: formData.number,
            complexity: formData.complexity,
            title: formData.title,
        };
        setquestionsHeading(body);
        let currentQuestions = [];
        let responseData = [];
    
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let buffer = '';
            const stopIdentifier = '3e6c447a3f45797db5b4b66cc99c412ff27a7b62efcd8ba7c8b482add861ed5a3d89a2828be5d201e5d7bd4eddbff3a2c3a58d62ba6278be1e5bfcab377dc2fd';
    
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
    
                buffer += decoder.decode(value, { stream: true });
                console.log('Current buffer:', buffer);
    
                // Check for stop identifier in the buffer
                if (buffer.includes(stopIdentifier)) {
                    console.log('Stopping streaming due to identifier match.');
                    break;
                }
    
                // Process complete JSON objects in the buffer
                while (true) {
                    const startIndex = buffer.indexOf('{');
                    const endIndex = buffer.indexOf('}', startIndex) + 1;
    
                    if (startIndex === -1 || endIndex === -1) break;
    
                    const jsonString = buffer.slice(startIndex, endIndex);
                    buffer = buffer.slice(endIndex);
    
                    try {
                        const parsedData = JSON.parse(jsonString);
                        console.log(parsedData); 
                        console.log('Parsed data');
    
                        // Check if message is an error and should trigger a retry
                        if (parsedData.msg && parsedData.msg === "OpenAi could not generate response, Please Try again" && 3 > 0) {
                            console.log('Received retry message, retrying...');
                            setLoading(true);  // Reset loading state if retrying
                            await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
                            await handleSubmit(formData);  // Call the function again, decrementing retry count
                            return;  // Exit current function execution to avoid additional processing
                        }
    
                        // Only process valid question data
                        if (!parsedData.msg || parsedData.msg !== "OpenAi could not generate response, Please Try again") {
                            if (parsedData.userId) {
                                responseData.push(parsedData);
                                console.log(responseData, "All response data");
    
                                setmcqResponseData(prevQuestions => {
                                    const newQuestions = [...prevQuestions];
    
                                    // Handle question grouping
                                    if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length > 0) {
                                        newQuestions.push([parsedData]);
                                    } else {
                                        newQuestions[newQuestions.length - 1].push(parsedData);
                                    }
    
                                    return newQuestions;
                                });
                            } else {
                                currentQuestions.push(parsedData);
                                console.log(currentQuestions, 'Current questions');
    
                                setLoading(false);
                                setQuestions(prevQuestions => {
                                    const newQuestions = [...prevQuestions];
    
                                    // Check if parsedData already exists in the existing chunks
                                    const isDuplicate = newQuestions.some(chunk => chunk.includes(parsedData));
    
                                    // If no duplicate found, add the parsedData
                                    if (!isDuplicate) {
                                        if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length >= formData.number) {
                                            newQuestions.push([parsedData]);
                                        } else {
                                            newQuestions[newQuestions.length - 1].push(parsedData);
                                        }
                                    }
    
                                    return newQuestions;
                                });
                            }
    
                            // If response contains stringified JSON in `response`, parse it
                            if (parsedData.response) {
                                const responseQuestions = JSON.parse(parsedData.response.replace(/'/g, '"'));
                                console.log(responseQuestions, 'Parsed response questions');
    
                                responseQuestions.forEach(question => {
                                    currentQuestions.push(question);
                                });
                            }
                        }
                    } catch (parseError) {
                        console.error('Error parsing JSON chunk:', parseError);
                        console.log('Malformed JSON string:', jsonString);
                    }
                }
            }
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('An error occurred while fetching questions.');
        } finally {
            setLoading(false);
            setIsSubmitting(false);
        }
        setIsregenareteshow(false)
    };

    const handleVariant = async (event, retryCount = 3) => {
        event.preventDefault();
        setStarred(false)
        setLoading1(true);
        setIsregenareteshow(true)
        const accessToken = localStorage.getItem('access_token');
        const googleToken = localStorage.getItem('token');

        if (!accessToken && !googleToken) {
            alert('Login to generate content');
            console.error('No access token or Google token found, redirecting to login...');
            window.location.href = '/signin';
            return;
        }

        if (!mcqResponseData || mcqResponseData.length === 0) {
            console.error('No previous response data available');
            setLoading1(false);
            return;
        }

        const lastIndex = mcqResponseData.length - 1;
        const item = mcqResponseData[lastIndex][0]|| mcqResponseData[lastIndex];
        
        if (!item || !item.userId || !item.id) {
            console.error('Invalid response data');
            setLoading1(false);
            return;
        }

        const user_id = item.userId;
        const response_id = item.id;

        // Determine which data to use based on availability
        let sendingData;
        if (sideFormData) {
            sendingData = {
                standard: sideFormData.standard,
                subject: sideFormData.subject,
                topic: sideFormData.topic || '',
                file_name: sideFormData.file_name,
                title: sideFormData.title,
                number: sideFormData.number,
                complexity: sideFormData.complexity
            };
            
        } else if ( formData) {
            // If main form data is available, use it as base
            sendingData = {
                standard: formData.standard,
                subject: formData.subject,
                topic: formData.topic || '',
                file_name: formData.file_name,
                title: formData.title,
                number:10,
                complexity: 'medium'
            };
        } else {
            console.error('No form data available');
            setLoading1(false);
            return;
        }

        console.log('Sending data to backend:', sendingData);

        const endpoint = `${baseEndpoint}/mcq/users/${user_id}/responses/${response_id}/variants_stream`;
        let currentQuestions = [];
        let responseData = []; // Array to hold response data with userId and other info
  
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sendingData),
            });
  
            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let buffer = '';
            const stopIdentifier = '3e6c447a3f45797db5b4b66cc99c412ff27a7b62efcd8ba7c8b482add861ed5a3d89a2828be5d201e5d7bd4eddbff3a2c3a58d62ba6278be1e5bfcab377dc2fd';
  
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
  
                buffer += decoder.decode(value, { stream: true });
                console.log('Current buffer:', buffer);
  
                // Check for stop identifier in the buffer
                if (buffer.includes(stopIdentifier)) {
                    console.log('Stopping streaming due to identifier match.');
                    break;
                }
  
                // Process complete JSON objects in the buffer
                while (true) {
                    const startIndex = buffer.indexOf('{');
                    const endIndex = buffer.indexOf('}', startIndex) + 1;
  
                    if (startIndex === -1 || endIndex === -1) break;
  
                    const jsonString = buffer.slice(startIndex, endIndex);
                    buffer = buffer.slice(endIndex);
  
                    try {
                        const parsedData = JSON.parse(jsonString);
                        console.log(parsedData, 'Parsed data');
  
                        // Handle retry logic if the "msg" is a retry message
                        if (parsedData.msg && parsedData.msg === "OpenAi could not generate response, Please Try again" && retryCount > 0) {
                            console.log('Received retry message, retrying...');
                            setLoading1(true);  // Reset loading state if retrying
                            await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
                            await handleVariant(event, retryCount - 1);  // Call the function again, decrementing retry count
                            return;  // Exit current function execution to avoid additional processing
                        }
  
                        // Only process valid question data
                        if (!parsedData.msg || parsedData.msg !== "OpenAi could not generate response, Please Try again") {
                            if (parsedData.userId) {
                                responseData.push(parsedData);
                                console.log(responseData, "All response data");
  
                                setmcqResponseData(prevQuestions => {
                                    const newQuestions = [...prevQuestions];
  
                                    console.log(formData.number,formData.numberOfQuestions,sideFormData.number,sideFormData.numberOfQuestions,"2 it is checking in the varients code")
                                    // Handle question grouping
                                    if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length >= sideFormData.number) {
                                        newQuestions.push([parsedData]);
                                    } else {
                                        newQuestions[newQuestions.length - 1].push(parsedData);
                                    }
  
                                    return newQuestions;
                                });
                            } else {
                                currentQuestions.push(parsedData);
                                console.log(currentQuestions, 'Current questions');
  
                                setLoading1(false);
                                setQuestions(prevQuestions => {
                                    const newQuestions = [...prevQuestions];
  
                                    // Check if parsedData already exists in the existing chunks
                                    const isDuplicate = newQuestions.some(chunk => chunk.includes(parsedData));
  
                                    // If no duplicate found, add the parsedData
                                    if (!isDuplicate) {
                                        console.log(formData.number,formData.numberOfQuestions,sideFormData.number,sideFormData.numberOfQuestions,"1 it is checking in the varients code")
                                        if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length >= sideFormData.number) {
                                            newQuestions.push([parsedData]);
                                        } else {
                                            newQuestions[newQuestions.length - 1].push(parsedData);
                                        }
                                    }
  
                                    return newQuestions;
                                });
                            }
  
                            // If response contains stringified JSON in `response`, parse it
                            if (parsedData.response) {
                                const responseQuestions = JSON.parse(parsedData.response.replace(/'/g, '"'));
                                console.log(responseQuestions, 'Parsed response questions');
  
                                responseQuestions.forEach(question => {
                                    currentQuestions.push(question);
                                });
                            }
                        }
                    } catch (parseError) {
                        console.error('Error parsing JSON chunk:', parseError);
                        console.log('Malformed JSON string:', jsonString);
                    }
                }
            }
  
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('An error occurred while fetching variants.');
        } finally {
            setLoading1(false);
        }
        setIsregenareteshow(false)
    };
    const handleRefreshQuestion = async (event, index, mcid) => {
        event.preventDefault();
        const accessToken = localStorage.getItem('access_token');
        const googleToken = localStorage.getItem('token');
    
        if (!accessToken && !googleToken) {
            alert('Login to generate content');
            console.error('No access token or Google token found, redirecting to login...');
            window.location.href = '/signin';
            return;
        }
        // const responsevalue = mcqResponseData[mcid]; // Get userId and responseId based on index
        // console.log(responsevalue,"it is the repoce value")
        // const userId = responsevalue['userId']
        // const id =  responsevalue['id']
        // console.log(userId, id, "Retrieved user ID and response ID from index");
        const item = mcqResponseData[mcid][0]; // Assuming you want the first item
        console.log(item,"it the all data to get usid rid")
        const { userId, id } = item; // Destructure userId and id
    
        console.log("User ID:",item.userId);
        console.log("ID:", item.id);
    
        // const endpoint = `http://localhost:8080/editable/users/${userId}/responses/${id}/edit`;
        const endpoint = `${baseEndpoint}/editable/users/${userId}/responses/${id}/edit`;
    
        const method = 'POST';
        const sendingdata = {
            index_number: index, 
        };
    
        try {
            const response = await axios({
                method: method,
                url: endpoint,
                data: sendingdata,
            });
    
            console.log(response.data, "Fetched single question data");
    
            
    
            // Check if the fetched data matches the current mcqResponseData
            if (questions[mcid]) {
              const newQuestionData = response.data.response; // This should be the new question object
          
              // Update the state to replace the specific question
              setQuestions(prevQuestions => {
                  return prevQuestions.map((innerArray, innerIndex) => {
                      // Check if we are in the right inner array
                      if (innerIndex === mcid) {
                          return innerArray.map((question, questionIndex) => {
                              // Replace the specific question object at questionIndex
                              if (questionIndex === index) {
                                  return { ...question, ...newQuestionData }; // Replace with new data
                              }
                              return question; // Keep the existing question
                          });
                      }
                      return innerArray; // Keep the existing inner array
                  });
              });
          } else {
              console.error("ID and User ID do not match.");
          }
        } catch (error) { 
          if (error) {
            console.error('Error 504: Gateway Timeout');
            alert('Server is taking too long to respond. Please try again later.');
            } else {
            console.error('Error fetching data:', error);
            }
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); 
        }
    };
    const questionContainerRef = useRef(null);

useEffect(() => {
    if (questionContainerRef.current) {
        questionContainerRef.current.scrollTop = questionContainerRef.current.scrollHeight;
    }
}, [questions]); 
    const renderQuestions = () => {
        return questions.map((group, index) => (
            <div key={index} className=" renderquestion " 
            >
                {index > 0 && (
                    <h4 className="m4" style={{textAlign:'end',marginRight:"40%",marginTop:'15px' }}>
                        Regenerated response {index}     
                    </h4>
                )}
              <div className="rederbox1"  >

              {group.map((questionObj, qIndex) => {
                    // Skip rendering question if its status is 'deleted' or 'reported'
                    if (questionObj.state == "deleted" || questionObj.state == "reported") {
                        return null; // Do not render the question
                    }else{
                        return (
                            <div 
                                key={qIndex} 
                                style={{
                                    position: 'relative',
                                    fontSize: '1rem',
                                    fontWeight: '400',
                                    paddingLeft: '10px',
                                    borderRadius: '5px',
                                }}
                            >
                                <div 
                                    onMouseEnter={() => setHoveredQuestion(`${index}-${qIndex}`)}
                                    onMouseLeave={() => {
                                        if (!showDropdown) {
                                            setHoveredQuestion(null);
                                        }
                                    }}
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative' }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '95%' }}>
                                        <span>{qIndex + 1}. {questionObj.question}</span>
                                        <div 
                                            style={{ position: 'relative', display: 'inline-block', marginLeft: '10px' }}
                                            onMouseEnter={(e) => {
                                                setShowDropdown(`${index}-${qIndex}`);
                                                const button = e.currentTarget.querySelector('button');
                                                if (button) {
                                                    button.style.backgroundColor = '#f5f5f5';
                                                    const icon = button.querySelector('svg');
                                                    if (icon) icon.style.color = '#666666';
                                                }
                                            }}
                                            onMouseLeave={(e) => {
                                                setShowDropdown(null);
                                                const button = e.currentTarget.querySelector('button');
                                                if (button) {
                                                    button.style.backgroundColor = 'transparent';
                                                    const icon = button.querySelector('svg');
                                                    if (icon) icon.style.color = '#C0C0C0';
                                                }
                                            }}
                                        >
                                            <button
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    border: 'none',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    padding: '3px 6px',
                                                    opacity: hoveredQuestion === `${index}-${qIndex}` ? 1 : 0,
                                                    transition: 'all 0.2s ease',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <BsThreeDotsVertical style={{ 
                                                    color: '#C0C0C0',
                                                    transition: 'color 0.2s ease',
                                                    fontSize: '16px',
                                                }} />
                                            </button>
                                            {showDropdown === `${index}-${qIndex}` && (
                                                <div 
                                                    style={{
                                                        position: 'absolute',
                                                        left: '100%',
                                                        top: '0',
                                                        backgroundColor: 'white',
                                                        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                                        borderRadius: '5px',
                                                        zIndex: 1000,
                                                        minWidth: '180px',
                                                        border: '1px solid #eee',
                                                    }}
                                                >
                                                    <div 
                                                        onClick={(event) => handleRefreshQuestion(event, qIndex, index)}
                                                        style={{
                                                            padding: '8px 15px', 
                                                            cursor: 'pointer',
                                                            borderBottom: '1px solid #eee',
                                                            transition: 'background-color 0.2s',
                                                        }}
                                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                                                        onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                                                    >
                                                        Regenerate Question
                                                    </div>
                                                    <div 
                                                        onClick={() => handleQuestionAction('delete', index, qIndex)}
                                                        style={{
                                                            padding: '8px 15px', 
                                                            cursor: 'pointer',
                                                            borderBottom: '1px solid #eee',
                                                            transition: 'background-color 0.2s',
                                                        }}
                                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                                                        onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                                                    >
                                                        Delete Question
                                                    </div>
                                                    <div 
                                                        onClick={() => handleQuestionAction('report', index, qIndex)}
                                                        style={{
                                                            padding: '8px 15px', 
                                                            cursor: 'pointer',
                                                            transition: 'background-color 0.2s',
                                                        }}
                                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                                                        onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                                                    >
                                                        Report Question
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <ul style={{marginBottom:'8px'}}>
                                        {questionObj.options.map((option, oIndex) => (
                                            <li key={oIndex}>{option}</li>
                                        ))}
                                    </ul> 
                                    {showAnswers && <li style={{ fontWeight: '800', maxWidth: '95%',marginBottom:'8px' }}>Answer: {questionObj.answer}</li>}
                                </div>
                            </div>
                        );
                    }
                })}
              </div>
              <div style={{width:'50%',display:'flex',alignItems:"start",justifyContent:'start',marginRight:'5%'}}>
                <div className="footer-left">
                    <b onClick={() => copyToClipboard(index)} className="footer-icon">
                        <GrCopy />
                    </b>
                    <b onClick={() => generatePDF(questions[index], questionsHeading)} className="footer-icon">
                        <LuDownload />
                    </b>
                    <b 
                    onClick={() => handlesave(index)} 
                    className="footer-icon"
                    >
                        {isstarred
                            ? <IoBookmark />
                            : <CiBookmark />
                        }
                    </b>
                
                </div>
              </div>

            </div>
          ));
    };

    const handlesave = async (tostarvalue) => {
        try {
            console.log(tostarvalue,"it is the index value")
          const tostar = mcqResponseData[tostarvalue];
          console.log(tostar, 'it is the data to be started');
        //   console.log(tostar.id,'it is normal ',tostar[tostarvalue].id,'it is the id to send')
          const starid = tostar.id || tostar[0].id;
      
          const endpoint = `${baseEndpoint}/editable/responses/${starid}/starred`;
      
          const response = await axios({
            method: 'post',
            url: endpoint,
          });
      
          const data = response.data; 
          console.log(data, "it is the new response data");
      
          setmcqResponseData(prevData => {
            const updatedData = [...prevData];
            updatedData[tostarvalue] = [data];
      
            return updatedData; 
          });
          
          setStarred(data.starred)
      
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
      
    
        const generatePDF = (questionsData, title) => {
            console.log(questionsData, "it is the data index is giving");
            const doc = new jsPDF();
        
            // Add Watermark
            const addWatermark = () => {
                doc.setFontSize(50);
                doc.setFont("helvetica", "bold");
        
                // Get page dimensions
                const pageWidth = doc.internal.pageSize.width;
                const pageHeight = doc.internal.pageSize.height;
        
                // Logo details
                const logoPath = "/images/Black-Logo.png";
                const logoWidth = 160;
                const logoHeight = 30;
        
                // Calculate position for the logo
                const logoX = (pageWidth - logoWidth) / 2 + 35;
                const logoY = (pageHeight - logoHeight) / 2 + 15;
        
                // Add the logo with reduced opacity
                doc.setGState(new doc.GState({ opacity: 0.1 }));
                doc.addImage(logoPath, "PNG", logoX, logoY, logoWidth, logoHeight, undefined, "FAST", 45);
        
                // Reset opacity
                doc.setGState(new doc.GState({ opacity: 1 }));
            };
        
            // Function to center text with an underline
            const centerTextWithUnderline = (text, y) => {
                const pageWidth = doc.internal.pageSize.width;
                
                // Split long text into multiple lines if necessary
                const textLines = doc.splitTextToSize(text, pageWidth - 40); 
                let lineY = y;
            
                // Draw each line separately
                textLines.forEach((line) => {
                    const textWidth = doc.getTextWidth(line);
                    const x = (pageWidth - textWidth) / 2;
                    doc.text(line, x, lineY);
                    lineY += 8;  // Move down for next line
                });
            
                // Draw underline for last line
                const lastLineWidth = doc.getTextWidth(textLines[textLines.length - 1]);
                const x = (pageWidth - lastLineWidth) / 2;
                doc.line(x, lineY, x + lastLineWidth, lineY);
                doc.line(x, lineY + 1, x + lastLineWidth, lineY + 1);
            };
            // Function to add a footer
            const addPageFooter = (pageNumber) => {
                const pageText = `Page ${pageNumber}`;
                doc.setFontSize(10);
                const pageWidth = doc.internal.pageSize.width;
                const textWidth = doc.getStringUnitWidth(pageText) * doc.internal.scaleFactor;
                const x = (pageWidth - textWidth) / 2;
                doc.text(pageText, x, 290);
            };
        
            let y = 30; // Starting position for content
            let pageNumber = 1;
        
            // Document Header
            doc.setFontSize(18);
            doc.setFont("helvetica", "bold");
            centerTextWithUnderline(`Class ${title.standard} - ${title.subject}`, 10);
            // Add filenames below the title
            if (title.file_name && title.file_name.length > 0) {
                doc.setFontSize(12);
                const pageWidth = doc.internal.pageSize.width;
            
                // Combine "Topics: " with filenames in one string
                doc.setFont("helvetica", "bold");
                const topicsText = "Topics: ";
                doc.setFont("helvetica", "normal");
                const fileNames = title.file_name.join(", ");
                const fullText = topicsText + fileNames;
            
                // Wrap text to fit within the page width
                const wrappedText = doc.splitTextToSize(fullText, pageWidth - 60);
            
                // Calculate total height for spacing
                let textY = 25; // Start position
                wrappedText.forEach((line) => {
                    const lineWidth = doc.getTextWidth(line);
                    const x = (pageWidth - lineWidth) / 2; // Center each line
                    doc.text(line, x, textY);
                    textY += 6; // Move down for the next line
                });
            
                // Adjust `y` for the next section
                y = textY + 10;
            }
            y +=2; 
            // Add watermark to the first page
            addWatermark();
        
            // Array to store answers
            let answersList = [];
        
            // Function to add questions and options
            const addQuestionsPage = (questions, startY, pageNumber) => {
                let y = startY;
                questions.forEach((item, index) => {
                    doc.setFontSize(13);
                    doc.setFont("helvetica", "bold");
        
                    // Render question
                    const questionText = `${index + 1}. ${item.question}`;
                    const questionLines = doc.splitTextToSize(questionText, 150);
                    const questionHeight = questionLines.length * 10;
        
                    // Calculate options height
                    const optionsHeight = item.options.reduce((total, option) => {
                        return total + doc.splitTextToSize(option, 150).length * 10 + 1;
                    }, 0);
        
                    // Add a new page if content overflows
                    if (y + questionHeight + optionsHeight > 270) {
                        addPageFooter(pageNumber);
                        doc.addPage();
                        pageNumber++;
                        addWatermark();
                        y = 20;
        
                        // Ensure consistent font size after page break
                        doc.setFontSize(13);
                        doc.setFont("helvetica", "bold");
                    }
        
                    // Render question text
                    questionLines.forEach((line) => {
                        doc.text(line, 10, y);
                        y += 10;
                    });
        
                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
        
                    // Render options
                    const optionOffsetX = 15;
                    item.options.forEach((option) => {
                        const optionLines = doc.splitTextToSize(option, 150);
                        optionLines.forEach((line) => {
                            doc.text(line, optionOffsetX, y);
                            y += 10;
                        });
                        y += 1;
                    });
        
                    // Store answer for later rendering
                    answersList.push(`${index + 1}. ${item.answer}`);
                    y += 10;
                });
                return pageNumber;
            };
        
            pageNumber = addQuestionsPage(questionsData, y, pageNumber);
        
            // Add footer for the last question page
            addPageFooter(pageNumber);
        
            // Add a new page for answers
            doc.addPage();
            pageNumber++;
            addWatermark();
        
            // Render Answers on a separate page
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.text("Answers", 10, 20);
            doc.setFontSize(12);
            doc.setFont("helvetica", "normal");
        
            let answerY = 30;
            answersList.forEach((answer, index) => {
                if (answerY > 280) {
                    addPageFooter(pageNumber);
                    doc.addPage();
                    pageNumber++;
                    addWatermark();
                    answerY = 20;
                }
                doc.text(answer, 10, answerY);
                answerY += 10;
            });
        
            // Add footer for the last answer page
            addPageFooter(pageNumber);
        
            // Save the document
            doc.save(`class_${title.standard}-${title.subject}.pdf`);
        };
    
    
    
    const contentRef = useRef(null);
    const letters = ['a', 'b', 'c', 'd'];
    const copyToClipboard = (key) => {
        console.log(key,'tis')
        const selectedQuestions = questions[key]; // Access the specific inner array based on the key
    
        if (!selectedQuestions || selectedQuestions.length === 0) {
            alert('No questions found for this key!');
            return;
        }
    
        const responseData = selectedQuestions.map((item, index) => {
            const optionsList = item.options.map((option, optionIndex) => `${letters[optionIndex]}) ${option}`).join('\n');
            return `${index + 1}. ${item.question}\nOptions:\n${optionsList}\nAnswer: ${item.answer}`;
        }).join('\n\n');
    
        navigator.clipboard.writeText(responseData)
            .then(() => {
                alert('Response copied to clipboard!');
                console.log(key, "is the key in copy code");
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
      };
      const formPageRef = useRef();
      console.log(formPageRef)
      const handleClearResponse = (e) => {
        e.preventDefault();

        // Reset MCQ-specific states
        setQuestions([]);  // Clear the questions array
        setmcqResponseData([]);  // Clear the response data
        setquestionsHeading(null);  // Reset the heading

        // Call the form clearing function
        if (formPageRef.current) {
            console.log("Calling clearForm...");
            formPageRef.current.clearForm(); // This will call the clearForm method in Formpage
        } else {
            console.log(formPageRef);
            console.log("formRef is not set!");
        }
    };
    
    // const location = useLocation(); 
    const itemData = location.state;
    useEffect(() => {
        if (itemData && itemData.response) {
          console.log(itemData, "it is the data from the history");
          setQuestions([itemData.response]); // Set questions only if itemData.response exists
          setmcqResponseData([itemData]);    // Set mcqResponseData only if itemData exists
          setStarred(itemData.starred);
          if(isstarred){
              setIshistory(true)
          }
          setquestionsHeading(itemData);
        }
      }, [itemData]);

    return (
        <div className="maincontainer">
            <div className="main-content">
                <div className="header">
                    <div className="header-left">
                        <div className="icon-container">
                            <div className="icon-circle">
                                <img
                                    src="/images/rectangles.svg"
                                    alt=""
                                    className="icon-img"
                                />
                            </div>
                            <p className="header-text">
                                {questionsHeading ? `${questionsHeading.number} MCQ's on ${questionsHeading.subject} for Class ${questionsHeading.standard} (${questionsHeading.complexity.charAt(0).toUpperCase() + questionsHeading.complexity.slice(1)} Difficulty)` : "MCQ's"}

                            </p>
                        </div>
                        <a href="#" onClick={handleClearResponse} className="clear-response">Clear Response</a>
                    </div>
                </div>

                {/* <div className="questions-container" ref={contentRef}>
                </div> */}
                    {loading ? (
                        <div style={{width:'100%',height:"75vh",display:'flex',alignItems:'center',justifyContent:'center',paddingRight:'20%',}}>
                            <img src="/images/Animation-2.gif" alt="" style={{width:'50px',height:'80px'}} />
                        </div>
                    ) : (
                        <div ref={questionContainerRef} style={{width:"100%",height:'75vh',overflowY:'scroll'}}> 
                        {renderQuestions()}
                        </div>
                    )}

                <div className="footer">
                    <div className="footerbox">
                        {/* <div className="footer-left">
                            <b onClick={() => copyToClipboard(mcqResponseData.length-1)} className="footer-icon">
                                <GrCopy />
                            </b>
                            <b onClick={() => generatePDF(questions[mcqResponseData.length-1], questionsHeading)} className="footer-icon">
                                <LuDownload />
                            </b>
                            <b 
                            onClick={() => handlesave(mcqResponseData.length - 1)} 
                            className="footer-icon"
                            >
                                {isstarred
                                    ? <IoBookmark />
                                    : <CiBookmark />
                                }
                            </b>
                        
                        </div> */}
                        <button
                            onClick={(event) => handleVariant(event)}
                            className="regenerate-button"
                            disabled={isregenareteshow || isstarred||ishistory}
                        >
                            <img src='/images/Vector.svg' className="button-icon" />
                            Regenerate response
                        </button>
                    </div>
                </div>
            </div>
            <div className="formbase">
                <Formpage 
                    ref={formPageRef}
                    onAnswersToggle={handleAnswersToggle}
                    onFormSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};
export default MCQ;