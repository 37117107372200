import React, { useState, useEffect, useImperativeHandle } from 'react';
import './Form.css';
import { useLocation } from 'react-router-dom';
import { baseEndpoint } from '../../../API/endpoints';
import { IoIosArrowDown ,IoIosArrowUp} from 'react-icons/io';
import { GoChevronRight } from 'react-icons/go';
const Formpage = React.forwardRef( ( props , ref) => {
  const { onAnswersToggle, onFormSubmit  } = props;
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [tempSelectedChapters, setTempSelectedChapters] = useState([]);
  const [numQuestions, setNumQuestions] = useState(10);
  const [difficulty, setDifficulty] = useState('medium');
  const [qType, setQType] = useState('Short Answers');
  const [isClassOpen, setIsClassOpen] = useState(false);
  const [isSubjectOpen, setIsSubjectOpen] = useState(false);
  const [hoveredSubject, setHoveredSubject] = useState(null);
  const [showChapterSelect, setShowChapterSelect] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [showAnswers, setShowAnswers] = useState(false);
  const [isQTypeOpen, setIsQTypeOpen] = useState(false);
  const location = useLocation();
  const isAssignment = location.pathname.includes('assignment');
  const [dataset, setDataset] = useState([]);
  useEffect(() => {
    
    fetchDataFromBackend();
  }, []);
  const fetchDataFromBackend = async () => {
    try {
        // const response = await fetch('http://localhost:8080/dataset/dataset_access');
        const response = await fetch(`${baseEndpoint}/dataset/dataset_access`);
        const data = await response.json();
        console.log('Fetched data:', data); // Log the data to check its structure
        // setSelectedClass(data);
        setDataset(data);
        console.log(data)
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


  const handleTitleClick = (subject, title) => {
    setSelectedSubject(subject);
    setSelectedTitle(title);
    setIsSubjectOpen(false);
    setSelectedChapters([]);
    setTempSelectedChapters([]);
    setShowChapterSelect(false);
  };

  const handleClassClick = (classNumber) => {
    setSelectedClass(classNumber);
    setIsClassOpen(false);
    // Clear subject, title and chapters when changing class
    setSelectedSubject('');
    setSelectedTitle('');
    setSelectedChapters([]);
    setTempSelectedChapters([]);
    setShowChapterSelect(false);
  };

  const handleClassDropdownClick = () => {
    setIsClassOpen(!isClassOpen);
    // Close other dropdowns
    setIsSubjectOpen(false);
    setShowChapterSelect(false);
    setIsQTypeOpen(false);
  };

  const handleSubjectDropdownClick = () => {
    if (selectedClass) {
      setIsSubjectOpen(!isSubjectOpen);
      // Close other dropdowns
      setIsClassOpen(false);
      setShowChapterSelect(false);
      setIsQTypeOpen(false);
    }
  };

  const handleChapterFieldClick = () => {
    if (selectedSubject) {
      setShowChapterSelect(!showChapterSelect);
      // Close other dropdowns
      setIsClassOpen(false);
      setIsSubjectOpen(false);
      setIsQTypeOpen(false);
      if (!showChapterSelect) {
        setTempSelectedChapters([...selectedChapters]);
      }
    }
  };

  const handleChapterChange = (chapter) => {
    const newTempSelectedChapters = tempSelectedChapters.includes(chapter)
      ? tempSelectedChapters.filter(ch => ch !== chapter)
      : [...tempSelectedChapters, chapter];
    setTempSelectedChapters(newTempSelectedChapters);
  };

  const handleApplyChapters = (e) => {
    if (e) e.stopPropagation();
    setSelectedChapters([...tempSelectedChapters]);
    setShowChapterSelect(false);
  };

  const handleGenerateClick = () => {
    if (!selectedClass || !selectedSubject || !selectedTitle || selectedChapters.length === 0) {
      alert('Please fill in all required fields');
      return;
    }

    const formData = {
      standard: selectedClass,
      subject: selectedSubject,
      title: selectedTitle,
      file_name: selectedChapters,
      number: numQuestions,
      complexity: difficulty,
      topic: "",
      ...(isAssignment && { q_type: qType })
    };

    if (onFormSubmit) {
      onFormSubmit(formData);
    }
  };

  const handleQTypeClick = (e) => {
    e.stopPropagation();
    setIsQTypeOpen(!isQTypeOpen);
    // Close other dropdowns
    setIsClassOpen(false);
    setIsSubjectOpen(false);
    setShowChapterSelect(false);
  };

  useEffect(() => {
    const questionsSlider = document.querySelector('.questions-slider');
    const difficultySlider = document.querySelector('.difficulty-slider');
    
    if (questionsSlider) {
      const percent = (numQuestions / 20) * 100;
      questionsSlider.style.background = `linear-gradient(to right, #4a72ff 0%, #4a72ff ${percent}%, #e0e0e0 ${percent}%, #e0e0e0 100%)`;
    }
    
    if (difficultySlider) {
      const value = difficulty === 'easy' ? 0 : difficulty === 'medium' ? 1 : 2;
      const percent = (value / 2) * 100;
      difficultySlider.style.background = `linear-gradient(to right, #4a72ff 0%, #4a72ff ${percent}%, #e0e0e0 ${percent}%, #e0e0e0 100%)`;
    }
  }, [numQuestions, difficulty]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.custom-select')) {
        setIsQTypeOpen(false);
        setIsClassOpen(false);
        setIsSubjectOpen(false);
        setShowChapterSelect(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Pre-fill form details from Mainform
    if (location.state) {
      console.log(location.state,'itis for location.state data')
      const { 
        selectedClass,
        selectedSubject,
        selectedTitle,
        selectedChapters,
        numberOfQuestions = 10,
        difficulty = 'medium',
        type 
      } = location.state;
      const{
        standard,
        subject,
        title,
        topic,
        complexity,
        number,
        file_name,

      }=location.state;
      
      setSelectedClass(selectedClass||standard);

      setSelectedSubject(selectedSubject||subject);
      setSelectedTitle(selectedTitle||title);
      setSelectedChapters(selectedChapters||file_name);
      setNumQuestions(numberOfQuestions||number);
      setDifficulty(difficulty||complexity);

      // Set Q Type for assignments
      if (type === 'assignment') {
        setQType('short answers');
      }
    }
  }, [location.state]);
  useImperativeHandle(ref, () => ({
    clearForm() {
      console.log("clearForm function called");
        setSelectedClass('');
        setSelectedSubject('');
        setSelectedTitle('');
        setSelectedChapters([]);
        setTempSelectedChapters([]);
        setNumQuestions(10);
        setDifficulty('medium');
        setQType('Short Answers');
        setIsClassOpen(false);
        setIsSubjectOpen(false);
        setShowChapterSelect(false);
        setIsMinimized(false);
        setShowAnswers(false);
    }
  }));
  return (
    <div className={`container ${isMinimized ? 'minimized' : ''}`}>
      <div 
        className="minimize-icon" 
        onClick={() => setIsMinimized(!isMinimized)}
        title={isMinimized ? "Maximize" : "Minimize"}
      >
        <div className={`formside-icon-container  ${isMinimized ? 'minimized' : ''}`}>
            <img src="/images/Group 1261153857.svg" alt="" className="formside-icon-img" />
        </div>
      </div>
      <div className="form-group">
        <div className="dropdown-container">
          <div 
            className={`custom-select ${isClassOpen ? 'open' : ''}`}
            onClick={handleClassDropdownClick}
          >
            <div className="formselect-header">
              {selectedClass ? `Class ${selectedClass}` : 'Select Class'}
              <span className="arrow">
                {isClassOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </span>
            </div>
            {isClassOpen && (
              <div className="dropdown-list">
                <div className="dropdown-scroll">
                  {dataset.map(item => (
                    <div 
                      key={item.class} 
                      className="class-option"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClassClick(item.class);
                      }}
                    >
                      Class {item.class}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="dropdown-container">
          <div 
            className={`custom-select ${!selectedClass ? 'disabled' : ''} ${isSubjectOpen ? 'open' : ''}`}
            onClick={handleSubjectDropdownClick}
          >
            <div className="formselect-header">
              {selectedSubject ? `${selectedSubject} - ${selectedTitle || ''}` : 'Select Subject'}
              <span className="arrow">
                {isSubjectOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </span>
            </div>
            {isSubjectOpen && selectedClass && (
              <div className="dropdown-list">
                <div className="dropdown-scroll">
                  {dataset
                    .find(item => item.class === selectedClass)
                    ?.subjects.map(subject => (
                      <div 
                        key={subject.subject} 
                        className="subject-option"
                        onMouseEnter={(e) => {
                          setHoveredSubject(subject.subject);
                          const rect = e.currentTarget.getBoundingClientRect();
                          const titleOptions = e.currentTarget.querySelector('.title-options');
                          if (titleOptions) {
                            titleOptions.style.position = 'fixed';
                            titleOptions.style.top = `${rect.top}px`;
                            titleOptions.style.left = `${rect.left -100}px`;
                          }
                        }}
                        // onMouseLeave={handleSubjectLeave}
                      >
                        <div className="subject-names ">
                          {subject.subject}
                          {subject.titles.length > 0 && <span className="arrow"><GoChevronRight /></span>}
                        </div>
                        {subject.titles.length > 0 && (
                          <div className="title-options">
                            {subject.titles.map(title => (
                              <div
                                key={title.title}
                                className="title-option"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleTitleClick(subject.subject, title.title);
                                }}
                              >
                                {title.title}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="dropdown-container">
          <div 
            className={`custom-select ${!selectedSubject ? 'disabled' : ''} ${showChapterSelect ? 'open' : ''}`}
            onClick={handleChapterFieldClick}
          >
            <div className="formselect-header">
              {selectedChapters.length > 0 ? (
                <div className="formchapter-pills">
                  {selectedChapters.slice(0, 2).map(chapter => (
                    <div 
                      key={chapter} 
                      className="formchapter-pill" 
                      title={chapter}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChapterChange(chapter);
                      }}
                    >
                      {chapter.length > 20 ? `${chapter.slice(0, 20)}...` : chapter}
                    </div>
                  ))}
                  {selectedChapters.length > 2 && (
                    <div 
                      className="show-more-wrapper"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="formchapter-pill show-more">
                        +{selectedChapters.length - 2} more
                      </div>
                      <div className="more-chapters-popup">
                        {selectedChapters.slice(2).map(chapter => (
                          <div 
                            key={chapter} 
                            className="popup-chapter"
                            onClick={() => handleChapterChange(chapter)}
                          >
                            {chapter}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <span>Select Chapters</span>
              )}
              <span className="arrow">
                {showChapterSelect? <IoIosArrowDown />: <IoIosArrowUp />} 
               </span>
            </div>
            {showChapterSelect && selectedSubject && (
              <div className="dropdown-list">
                
                  <div className="chapter-section">
                    <div className="chapter-list" style={{backgroundColor:"#F5F8FF"}}>
                    <div className="dropdown-scroll">
                      {dataset
                        .find(item => item.class === selectedClass)
                        ?.subjects.find(sub => sub.subject === selectedSubject)
                        ?.titles.find(t => t.title === selectedTitle)
                        ?.chapters.map(chapter => (
                          <div 
                            key={chapter} 
                            className={`chapter-item ${tempSelectedChapters.includes(chapter) ? 'selected' : ''}`}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <input
                              type="checkbox"
                              id={chapter}
                              checked={tempSelectedChapters.includes(chapter)}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleChapterChange(chapter);
                              }}
                            />
                            <label 
                              htmlFor={chapter}
                              onClick={(e) => e.stopPropagation()}
                            >
                              {chapter}
                            </label>
                          </div>
                        ))}
                    </div>
                    <div className="chapter-actions">
                      <button 
                        className="apply-button"
                        onClick={handleApplyChapters}
                        disabled={tempSelectedChapters.length === 0}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {isAssignment && (
          <div className="dropdown-container">
            <div 
              className={`custom-select ${isQTypeOpen ? 'open' : ''}`}
              onClick={handleQTypeClick}
            >
              <div className="formselect-header">
                {qType || 'Question Type'}
                <span className="arrow">
                  {isQTypeOpen ?<IoIosArrowDown />: <IoIosArrowUp />}</span>
              </div>
              {isQTypeOpen && (
                <div className="dropdown-list">
                  <div className="dropdown-scroll">
                    <div className="chapter-section">
                      <div className="chapter-list" style={{backgroundColor:"#F5F8FF"}}>
                        <div 
                          className={`chapter-item ${qType === 'Short Answers' ? 'selected' : ''}`}
                          onClick={() => setQType('Short Answers')}
                        >
                          Short Answers
                        </div>
                        <div 
                          className={`chapter-item ${qType === 'Long Answers' ? 'selected' : ''}`}
                          onClick={() => setQType('Long Answers')}
                        >
                          Long Answers
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="slider-section">
          <label>
              No of Questions: <span className="question-count-box">{numQuestions}</span>
                <div className="formslider-container">
              <input
                type="range"
                min="1"
                max="20"
                value={numQuestions}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setNumQuestions(value);
                  const percent = (value / 20) * 100;
                  e.target.style.background = `linear-gradient(to right, #4a72ff 0%, #4a72ff ${percent}%, #e0e0e0 ${percent}%, #e0e0e0 100%)`;
                }}
                className="sideformslider questions-slider"
              />
              <div className="slider-ticks">
                {Array.from({ length: 41 }, (_, i) => (
                  <span key={i} className="tick"></span>
                ))}
              </div>
              <div className="slider-labels">
                <span>0</span>
                <span>10</span>
                <span>20</span>
              </div>
            </div>
          </label>
        </div>

        <div className="slider-section">
          <label>
            Difficulty
            <div className="formslider-container">
              <input
                type="range"
                min="0"
                max="2"
                step="1"
                value={difficulty === 'easy' ? 0 : difficulty === 'medium' ? 1 : 2}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  const values = ['easy', 'medium', 'hard'];
                  setDifficulty(values[value]);
                  const percent = (value / 2) * 100;
                  e.target.style.background = `linear-gradient(to right, #4a72ff 0%, #4a72ff ${percent}%, #e0e0e0 ${percent}%, #e0e0e0 100%)`;
                }}
                className="sideformslider difficulty-slider"
              />
              <div className="slider-ticks">
                {Array.from({ length: 41 }, (_, i) => (
                  <span key={i} className="tick"></span>
                ))}
              </div>

              <div className="difficulty-labels">
                <span className={difficulty === 'easy' ? 'active' : ''}>Easy</span>
                <span className={difficulty === 'medium' ? 'active' : ''}>Medium</span>
                <span className={difficulty === 'hard' ? 'active' : ''}>Hard</span>
              </div>
            </div>
          </label>
        </div>

        <div className="toggle-section">
          <label className="toggle-label">
            With Answers
            <div className="toggle-switch">
              <input
                type="checkbox"
                checked={showAnswers}
                onChange={(e) => {
                  setShowAnswers(e.target.checked);
                  if (onAnswersToggle) {
                    onAnswersToggle(e.target.checked);
                  }
                }}
                className="toggle-input"
              />
              <span className="toggle-slider"></span>
            </div>
          </label>
        </div>

        <button 
          className="generate-btn me-3 mb-2 mt-2"
          onClick={handleGenerateClick}
        >
          <img src="/images/rectangles.svg" alt="" />
          Generate
        </button>
      </div>
    </div>
  );
});

export default Formpage;  