import React, { useEffect, useRef, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import axios from "axios";
import "./Header.css";
import { baseEndpoint } from "./API/endpoints";

const RightSection = ({ children, leftWidth, isMobileView, toggleLeftMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userIconRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [userInitials, setUserInitials] = useState("");
  const [userPresent, setUserPresent] = useState(false);

  const options = [
    { value: "", label: "Select" },
    { value: "mcq", label: "MCQ" },
    { value: "assignment", label: "Assignment" },
  ];

  useEffect(() => {
    const routeToOptionMap = {
      "/mainform": location.state?.type || "",
      "/mcq": "mcq",
      "/assignment": "assignment",
      "/summarization": "summarization",
    };
    setSelectedOption(routeToOptionMap[location.pathname] || "");
  }, [location.pathname, location.state]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userdetail")) || JSON.parse(localStorage.getItem("userdata"));
    if (userData) {
      // const fullNameInitial = userData.full_name.charAt(0).toUpperCase();
      const fullNameInitial = userData.full_name.charAt(0).toUpperCase();
      setUserInitials(fullNameInitial);
      setUserPresent(true);
    }
  }, []);

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setIsDropdownOpen(false);
    switch (value) {
      case "mcq":
      case "assignment":
        navigate("/mainform", { state: { type: value } });
        break;
      case "summarization":
        navigate("/summarization");
        break;
      default:
        navigate("/");
        break;
    }
  };

  const handleSignOut = async () => {
    let endpoint = "";
    let method = "";
    let tokenType = "";
    let accessToken = "";

    if (localStorage.getItem("access_token")) {
      endpoint = `${baseEndpoint}/userauth/logout`;
      method = "POST";
      tokenType = localStorage.getItem("token_type");
      accessToken = localStorage.getItem("access_token");
    } else {
      endpoint = `${baseEndpoint}/googleauth/google/logout`;
      method = "GET";
      tokenType = "Token";
      accessToken = localStorage.getItem("token");
    }

    try {
      const response = await axios({
        method,
        url: endpoint,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `${tokenType} ${accessToken}`,
        },
      });

      if (response.status === 200 || response.data.msg === "Token expired or revoked") {
        localStorage.clear();
        navigate("/signin");
      }
    } catch (error) {
      if (error.response?.data.msg === "Token expired or revoked") {
        localStorage.clear();
        navigate("/signin");
      }
      console.error("Error during sign-out:", error.response);
    }
  };

  const handleClickOutside = (event) => {
    if (
      userIconRef.current &&
      !userIconRef.current.contains(event.target)
    ) {
      setIsUserDropdownOpen(false);
    }
    if (!event.target.closest(".custom-dropdown")) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="right-section"
      style={{ flex: 1, marginLeft: leftWidth !== "5%" ? leftWidth : "0", transition: "margin-left 0.4s ease" }}
    >
      <div className="headheader">
        <div className="custom-dropdown">
          <div className="dropdown-header" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {options.find((opt) => opt.value === selectedOption)?.label || "Select"}
            <FaCaretDown className="dropdown-arrow" />
          </div>
          {isDropdownOpen && (
            <ul className="dropdown-options">
              {options.map((option) => (
                <li
                  key={option.value}
                  className="dropdown-option"
                  onClick={() => handleOptionClick(option.value)}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="user-icon-container position-relative" ref={userIconRef}>
          <div className="user-icon d-flex align-items-center justify-content-center" onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}>
            {userInitials || <AiOutlineUser style={{ color: "white", fontSize: "30px" }} />}
          </div>
          {isUserDropdownOpen && (
            <div className="user-dropdown position-absolute bg-white shadow rounded" style={{ top: "100%", right: 0, zIndex: 2000, minWidth: "150px" }}>
              {userPresent ? (
                <button className="dropdown-item" onClick={handleSignOut}>Sign Out</button>
              ) : (
                <button className="dropdown-item" onClick={() => navigate("/signin")}>Sign In</button>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="content-section child-component">{children}</div>
    </div>
  );
};

export default RightSection;
