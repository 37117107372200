import React, { useEffect, useState } from "react";
import LeftMenu from "./Leftmenu";
import RightSection from "./Header";
const BaseLayout = ({ children }) => {
  const [leftWidth, setLeftWidth] = useState('5%'); // Default size for desktop
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for mobile sidebar
  const [isMobileView, setIsMobileView] = useState(false); // To check if the view is mobile


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle mobile sidebar visibility
  };

  useEffect(() => {
    // const checkMobileView = () => {
    //   setIsMobileView(window.innerWidth <= 868); // Check if the screen width is below 768px (mobile size)
    // };
    // checkMobileView();
    // window.addEventListener("resize", checkMobileView);

    // return () => {
    //   window.removeEventListener("resize", checkMobileView);
    // };
  }, []);

  return (
    <div className="d-flex" style={{ height: '100vh', width: '100%' }}>
    {/* Left Menu Component */}
 
      <LeftMenu leftWidth={leftWidth} />
    

    {/* Right Section */}
    <RightSection leftWidth={leftWidth} isMobileView={isMobileView} toggleLeftMenu={toggleSidebar}>
      {children}
    </RightSection>
  </div>
  );
};

export default BaseLayout;
