import React, { useState, useEffect, useRef } from 'react';
import './Mainform.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { baseEndpoint } from '../../API/endpoints';


const Mainform = (path) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [formType, setFormType] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [isSubjectOpen, setIsSubjectOpen] = useState(false);
  const [isChapterOpen, setIsChapterOpen] = useState(false);
  const [isClassOpen, setIsClassOpen] = useState(false);
  const [hoveredSubject, setHoveredSubject] = useState(null);
  const [showAllChips, setShowAllChips] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState('10');
  const [difficulty, setDifficulty] = useState('medium');
  const CHIPS_TO_SHOW = 2;

  const subjectRef = useRef(null);
  const chapterRef = useRef(null);
  const classRef = useRef(null);

  const [basedata, setDataset] = useState([]);
  useEffect(() => {
    
    fetchDataFromBackend();
  }, []);
  const fetchDataFromBackend = async () => {
    try {
        // const response = await fetch('http://localhost:8080/dataset/dataset_access');
        const response = await fetch(`${baseEndpoint}/dataset/dataset_access`);
        const data = await response.json();
        console.log('Fetched data:', data); // Log the data to check its structure
        // setSelectedClass(data);
        setDataset(data);
        console.log(data)
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

  useEffect(() => {
    // If there's state passed from navigation, pre-fill the form
    if (location.state) {
      // Reset all form fields first
      setSelectedClass('');
      setSelectedSubject('');
      setSelectedTitle('');
      setSelectedChapters([]);
      setNumberOfQuestions('10');
      setDifficulty('medium');

      const { 
        selectedClass = '6', 
        selectedSubject = 'English', 
        selectedTitle = 'Poorvi', 
        selectedChapters = ['Culture and Tradition'],
        numberOfQuestions = '10',
        difficulty = 'medium',
        type 
      } = location.state;

      // Set state for class dropdown
      setSelectedClass(selectedClass);

      // Find and set the corresponding subject
      const classData = basedata.find(cls => cls.class === selectedClass);
      if (classData) {
        const subjectData = classData.subjects.find(sub => sub.subject === selectedSubject);
        if (subjectData) {
          setSelectedSubject(selectedSubject);
          
          // Find and set the corresponding title
          const titleData = subjectData.titles.find(title => title.title === selectedTitle);
          if (titleData) {
            setSelectedTitle(selectedTitle);
            
            // Set chapters
            setSelectedChapters(selectedChapters);
          }
        }
      }

      // Set number of questions and difficulty
      setNumberOfQuestions(numberOfQuestions);
      setDifficulty(difficulty);

      // Set form type for additional context
      setFormType(type || '');
    }
  }, [location.state]);

  useEffect(() => {
    // Extract form type from location state
    const { state } = location;
    if (state && state.type) {
      // Map the form type to match header dropdown exactly
      const formTypeMap = {
        'mcq': 'MCQ',
        'assignment': 'Assignment',
        'summarization': 'Summarization'
      };
      setFormType(formTypeMap[state.type] || state.type);
      
      // Reset form fields
      setSelectedClass('');
      setSelectedSubject('');
      setSelectedTitle('');
      setSelectedChapters([]);
      setNumberOfQuestions('10');
      setDifficulty('medium');
    }
  }, [location]);

  useEffect(() => {
    setShowAllChips(false);
  }, [selectedClass, selectedSubject, selectedTitle]);

  useEffect(() => {
    setSelectedClass('');
    setSelectedSubject('');
    setSelectedTitle('');
    setSelectedChapters([]);
    setNumberOfQuestions('10');
    setDifficulty('medium');
  }, [formType]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (subjectRef.current && !subjectRef.current.contains(event.target)) {
        setIsSubjectOpen(false);
        setHoveredSubject(null);
      }
      if (chapterRef.current && !chapterRef.current.contains(event.target)) {
        setIsChapterOpen(false);
      }
      if (classRef.current && !classRef.current.contains(event.target)) {
        setIsClassOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Get available classes from basedata
  const classes = basedata.map(item => item.class);

  // Get subjects with titles based on selected class
  const subjectsWithTitles = selectedClass
    ? basedata.find(item => item.class === selectedClass)?.subjects || []
    : [];

  // Get chapters based on selected subject and title
  const chapters = selectedClass && selectedSubject && selectedTitle
    ? basedata
        .find(item => item.class === selectedClass)
        ?.subjects.find(sub => sub.subject === selectedSubject)
        ?.titles.find(t => t.title === selectedTitle)
        ?.chapters || []
    : [];

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
    setSelectedSubject('');
    setSelectedTitle('');
    setSelectedChapters([]);
    setHoveredSubject(null);
  };

  const handleTitleSelect = (subject, title) => {
    setSelectedSubject(subject);
    setSelectedTitle(title);
    setSelectedChapters([]);
    setIsSubjectOpen(false);
  };

  const toggleChapter = (chapter) => {
    if (selectedChapters.includes(chapter)) {
      setSelectedChapters(selectedChapters.filter(ch => ch !== chapter));
    } else {
      setSelectedChapters([...selectedChapters, chapter]);
    }
  };

  const removeChapter = (chapter) => {
    setSelectedChapters(selectedChapters.filter(ch => ch !== chapter));
  };

  const getDisplayText = () => {
  
    if (!selectedTitle) return selectedSubject;
    return `${selectedSubject} - ${selectedTitle}`;
  };

  const handleSubmit = () => {
    const state = {
      selectedClass: selectedClass ,
      selectedSubject: selectedSubject,
      selectedTitle: selectedTitle ,
      selectedChapters: selectedChapters,
      numberOfQuestions: numberOfQuestions,
      difficulty: difficulty,
      autoSubmit: true
    };
    
    switch (formType) {
      case 'MCQ':
        navigate('/mcq', { state, replace: true });
        break;
      case 'Assignment':
        navigate('/assignment', { 
          state: {
            ...state,
            q_type: 'Short Answers'  // Add q_type for assignments
          }, 
          replace: true 
        });
        break;
      case 'Summarization':
        navigate('/summarization');
        break;
      default:
        break;
    }
  };

  return (
    <div className="mainformcontainer">
      <h1 className='mainformheading'>{formType ? `${formType}` : 'Select Form Type'}</h1>
      <div className="form-container">
        <div className="dropdown-row">
          <div className="select-wrapper" ref={classRef}>
            <label className={`floating-label ${selectedClass ? 'float' : ''}`}>
              Select Class
            </label>
            <div 
              className={`select-header ${isClassOpen ? 'open' : ''} ${selectedClass ? 'has-value' : ''}`}
              onClick={() => setIsClassOpen(!isClassOpen)}
              style={{ paddingTop: selectedClass ? '24px' : '16px', paddingBottom: selectedClass ? '8px' : '16px' }}
            >
              <span className="select-text">{selectedClass ? `Class ${selectedClass}` : ''}</span>
              <span className="arrow-down"> <FaAngleDown /></span>
            </div>
            {isClassOpen && (
              <div className="select-options">
                {classes.map((cls) => (
                  <div
                    key={cls}
                    className={`select-option ${selectedClass === cls ? 'selected' : ''}`}
                    onClick={() => {
                      handleClassChange({ target: { value: cls } });
                      setIsClassOpen(false);
                    }}
                  >
                    <span className="option-text">Class {cls}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="select-wrapper" ref={subjectRef}>
            <label className={`floating-label ${selectedSubject ? 'float' : ''}`}>
              Subject 
            </label>
            <div 
              className={`select-header ${isSubjectOpen ? 'open' : ''} ${selectedSubject ? 'has-value' : ''}`}
              onClick={() => setIsSubjectOpen(!isSubjectOpen)}
              style={{ paddingTop: selectedSubject ? '24px' : '16px', paddingBottom: selectedSubject ? '8px' : '16px' }}
            >
              <span className="select-text">{getDisplayText()}</span>
              <span className="arrow-down"> <FaAngleDown /></span>
            </div>
            {isSubjectOpen && (
              <div className="select-options">
                {subjectsWithTitles.map((subject) => (
                  <div
                    key={subject.subject}
                    className={`select-option ${selectedSubject === subject.subject ? 'selected' : ''}`}
                    onMouseEnter={() => setHoveredSubject(subject.subject)}
                    onMouseLeave={() => setHoveredSubject(null)}
                  >
                    <span className="option-text">{subject.subject}</span>
                    <span className="arrow-right"> <FaAngleRight /></span>
                    {hoveredSubject === subject.subject && (
                      <div className="nested-select">
                        {subject.titles.map((title) => (
                          <div
                            key={title.title}
                            className={`nested-option ${
                              selectedSubject === subject.subject && 
                              selectedTitle === title.title ? 'selected' : ''
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleTitleSelect(subject.subject, title.title);
                            }}
                          >
                            {title.title}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="mainformchapter-section">
          <div className="select-wrapper chapter-select" ref={chapterRef}>
            <label className={`floating-label ${selectedChapters.length > 0 ? 'float' : ''}`}>
              Chapter
            </label>
            <div 
              className={`select-header ${isChapterOpen ? 'open' : ''} ${selectedChapters.length > 0 ? 'has-value' : ''}`}
              onClick={() => setIsChapterOpen(!isChapterOpen)}
              style={{ paddingTop: selectedChapters.length > 0 ? '24px' : '16px', paddingBottom: selectedChapters.length > 0 ? '8px' : '16px' }}
            >
              <div className="header-content">
                {selectedChapters.length > 0 && (
                  <div className="header-chips">
                    {(showAllChips ? selectedChapters : selectedChapters.slice(0, CHIPS_TO_SHOW)).map((chapter) => (
                      <div key={chapter} className="header-chip" onClick={(e) => {
                        e.stopPropagation();
                        removeChapter(chapter);
                      }}>
                        <span className="chip-text">{chapter}</span>
                        <span className="chip-remove">×</span>
                      </div>
                    ))}
                    {!showAllChips && selectedChapters.length > CHIPS_TO_SHOW && (
                      <div 
                        className="header-chip show-more-chip"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowAllChips(true);
                        }}
                      >
                        <span className="chip-showless">+{selectedChapters.length - CHIPS_TO_SHOW} more</span>
                      </div>
                    )}
                    {showAllChips && selectedChapters.length > CHIPS_TO_SHOW && (
                      <div 
                        className="header-chip show-less-chip"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowAllChips(false);
                        }}
                      >
                        <span className="chip-showless">Show Less</span>
                      </div>
                    )}
                  </div>
                )}
                </div>
                <span className="arrow-down"> <FaAngleDown /></span>
            </div>
            {isChapterOpen && (
              <div className="select-options chapter-options">
                {chapters.map((chapter) => (
                  <div
                    key={chapter}
                    className={`select-option ${selectedChapters.includes(chapter) ? 'selected' : ''}`}
                    onClick={() => toggleChapter(chapter)}
                  >
                    <span className="option-text">{chapter}</span>
                    {selectedChapters.includes(chapter) && (
                      <span className="checkmark">✓</span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <button 
          className="generate-btn"
          disabled={selectedChapters.length === 0}
          onClick={handleSubmit}
        >
          <img src="/images/rectangles.svg" alt="" />
          Generate
        </button>
      </div>
    </div>
  );
};

export default Mainform;